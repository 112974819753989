import { io } from 'socket.io-client'

export default (ctx, inject) => {
	const socket = io(process.env.WEBSOCKET_DOMAIN, {
		query: {},
		autoConnect: false,
		transports: ['websocket', 'polling'] // use WebSocket first, if available
	})

	socket.on('connect_error', () => {
		// revert to classic upgrade
		socket.io.opts.transports = ['polling', 'websocket']
	})

	inject('socket', socket)
}
