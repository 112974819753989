export default (ctx, inject) => {
	const getCurrencySymbol = () => {
		const formatter = new Intl.NumberFormat(
			ctx.store.state.locale, {
				style: 'currency',
				currency: ctx.store.state.merchant.currencyCode
			}
		).formatToParts(0)

		return formatter.find(p => p.type === 'currency')?.value || ''
	}

	const toCurrency = (price, currencyCode) => {
		let currency = ctx.i18n.n(
			price,
			{
				style: 'currency',
				currency: currencyCode || ctx.store.state.merchant.currencyCode,
				locale: ctx.store.state.locale
			}
		)

		const intlCurrency = getCurrencySymbol()

		if (intlCurrency && ctx.store.state.merchant.customAttributes.currency_symbol && intlCurrency !== ctx.store.state.merchant.customAttributes.currency_symbol) {
			currency = currency.replace(intlCurrency, ctx.store.state.merchant.customAttributes.currency_symbol)
		}

		return currency
	}

	const getCurrencyDecimalPlaces = (currencyCode) => {
		const numberFormat = new Intl.NumberFormat(ctx.store.state.locale, {
			style: 'currency',
			currency: currencyCode || ctx.store.state.merchant.currencyCode
		}).resolvedOptions()

		return numberFormat.maximumFractionDigits
	}

	const getMinDecimalValue = (currencyCode) => {
		return 1 / Math.pow(10, getCurrencyDecimalPlaces(currencyCode))
	}

	const transformNumber = (value, currencyCode) => {
		return parseFloat((+value).toFixed(getCurrencyDecimalPlaces(currencyCode)))
	}

	inject('currency', {
		getCurrencySymbol,
		toCurrency,
		getCurrencyDecimalPlaces,
		getMinDecimalValue,
		transformNumber
	})
}
