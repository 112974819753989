export default (ctx, inject) => {
	if (typeof window.require === 'function') {
		const { remote, ipcRenderer } = window.require('electron')
		const bridge = remote.require('./bridge')

		ipcRenderer.on('print-barcode-label', (e, prn) => bridge.printBarcodeLabel(prn))
		ipcRenderer.on('factory-reset', () => {
			bridge.deleteRealm()
			ipcRenderer.send('factory-reset-done')
		})

		inject('bridge', bridge)
	} else if (typeof window.bridge === 'object') {
		inject('bridge', window.bridge)
	}
}
