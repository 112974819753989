export default {
	activateTab: ($event) => {
		const nav = $event.currentTarget.closest('.nav')
		const content = document.getElementById($event.currentTarget.getAttribute('data-content'))

		nav.querySelectorAll('a').forEach(a => a.classList.remove('active'))
		$event.currentTarget.classList.add('active')
		document.querySelectorAll('.' + nav.getAttribute('data-content'))
			.forEach(tab => tab.classList.add('d-none'))

		if (content) { content.classList.remove('d-none') }
	},
	activateCard: ($event) => {
		$event.currentTarget.closest('.card-container, .card-deck').querySelectorAll('.card')
			.forEach((card) => {
				if (card.isEqualNode($event.currentTarget)) {
					card.classList.remove('bg-white')
					card.classList.add('active', 'bg-primary', 'text-white')
				} else {
					card.classList.remove('active', 'bg-primary', 'text-white')
					card.classList.add('bg-white')
				}
			})
	}
}
