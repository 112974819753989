import Vue from 'vue'

Vue.filter('mask', (value, type) => {
	if (!value) { return value }

	switch (type) {
		case 'mobile':
			value = 'xxxxxx' + value.substr(-4)

			break

		case 'email': {
			const emailArr = value.split('@')
			const lettersArr = emailArr[0].split('')

			value = lettersArr.map((letter, index) => {
				return [0, (lettersArr.length - 1)].includes(index) ? letter : '*'
			}).join('') + '@' + emailArr[1]

			break
		}
	}

	return value
})

Vue.filter('formatIfEmpty', (value, str) => {
	return !value ? str : value
})

Vue.filter('capitalize', (value) => {
	if (!value) {
		return ''
	}

	value = value.toString()

	return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('capitalizeAll', (value) => {
	if (!value) {
		return ''
	}

	return value.toString().split(' ').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(' ')
})
