/* eslint-disable */
import 'offline-js'

Offline.options = {
	checkOnLoad: true,
	interceptRequests: false,
	checks: {
		xhr: {
			url: '/api/connect'
		}
	},
	requests: false
}

Offline.check()

export default (ctx, inject) => {
	inject('offline', Offline)
}
