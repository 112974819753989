export const AdditionalInfoModal = () => import('../../components/additional-info-modal.vue' /* webpackChunkName: "components/additional-info-modal" */).then(c => wrapFunctional(c.default || c))
export const App = () => import('../../components/app.vue' /* webpackChunkName: "components/app" */).then(c => wrapFunctional(c.default || c))
export const BrandList = () => import('../../components/brand-list.vue' /* webpackChunkName: "components/brand-list" */).then(c => wrapFunctional(c.default || c))
export const CallerIdModal = () => import('../../components/caller-id-modal.vue' /* webpackChunkName: "components/caller-id-modal" */).then(c => wrapFunctional(c.default || c))
export const Cart = () => import('../../components/cart.vue' /* webpackChunkName: "components/cart" */).then(c => wrapFunctional(c.default || c))
export const CashDrawerModal = () => import('../../components/cash-drawer-modal.vue' /* webpackChunkName: "components/cash-drawer-modal" */).then(c => wrapFunctional(c.default || c))
export const Cash = () => import('../../components/cash.vue' /* webpackChunkName: "components/cash" */).then(c => wrapFunctional(c.default || c))
export const CategoryList = () => import('../../components/category-list.vue' /* webpackChunkName: "components/category-list" */).then(c => wrapFunctional(c.default || c))
export const ChargeList = () => import('../../components/charge-list.vue' /* webpackChunkName: "components/charge-list" */).then(c => wrapFunctional(c.default || c))
export const ChargesModal = () => import('../../components/charges-modal.vue' /* webpackChunkName: "components/charges-modal" */).then(c => wrapFunctional(c.default || c))
export const Checkout = () => import('../../components/checkout.vue' /* webpackChunkName: "components/checkout" */).then(c => wrapFunctional(c.default || c))
export const Clover = () => import('../../components/clover.vue' /* webpackChunkName: "components/clover" */).then(c => wrapFunctional(c.default || c))
export const CustomSale = () => import('../../components/custom-sale.vue' /* webpackChunkName: "components/custom-sale" */).then(c => wrapFunctional(c.default || c))
export const CustomerModal = () => import('../../components/customer-modal.vue' /* webpackChunkName: "components/customer-modal" */).then(c => wrapFunctional(c.default || c))
export const CustomerOtpModal = () => import('../../components/customer-otp-modal.vue' /* webpackChunkName: "components/customer-otp-modal" */).then(c => wrapFunctional(c.default || c))
export const Customers = () => import('../../components/customers.vue' /* webpackChunkName: "components/customers" */).then(c => wrapFunctional(c.default || c))
export const Denominations = () => import('../../components/denominations.vue' /* webpackChunkName: "components/denominations" */).then(c => wrapFunctional(c.default || c))
export const DeviceList = () => import('../../components/device-list.vue' /* webpackChunkName: "components/device-list" */).then(c => wrapFunctional(c.default || c))
export const DiscountList = () => import('../../components/discount-list.vue' /* webpackChunkName: "components/discount-list" */).then(c => wrapFunctional(c.default || c))
export const DiscountModal = () => import('../../components/discount-modal.vue' /* webpackChunkName: "components/discount-modal" */).then(c => wrapFunctional(c.default || c))
export const DynamicDiscountModal = () => import('../../components/dynamic-discount-modal.vue' /* webpackChunkName: "components/dynamic-discount-modal" */).then(c => wrapFunctional(c.default || c))
export const EmployeeList = () => import('../../components/employee-list.vue' /* webpackChunkName: "components/employee-list" */).then(c => wrapFunctional(c.default || c))
export const Employees = () => import('../../components/employees.vue' /* webpackChunkName: "components/employees" */).then(c => wrapFunctional(c.default || c))
export const FloorList = () => import('../../components/floor-list.vue' /* webpackChunkName: "components/floor-list" */).then(c => wrapFunctional(c.default || c))
export const ForeignCurrencyModal = () => import('../../components/foreign-currency-modal.vue' /* webpackChunkName: "components/foreign-currency-modal" */).then(c => wrapFunctional(c.default || c))
export const GiftCardInput = () => import('../../components/gift-card-input.vue' /* webpackChunkName: "components/gift-card-input" */).then(c => wrapFunctional(c.default || c))
export const GiftCardPurchase = () => import('../../components/gift-card-purchase.vue' /* webpackChunkName: "components/gift-card-purchase" */).then(c => wrapFunctional(c.default || c))
export const GoogleMap = () => import('../../components/google-map.vue' /* webpackChunkName: "components/google-map" */).then(c => wrapFunctional(c.default || c))
export const Import = () => import('../../components/import.vue' /* webpackChunkName: "components/import" */).then(c => wrapFunctional(c.default || c))
export const InternationalBancard = () => import('../../components/international-bancard.vue' /* webpackChunkName: "components/international-bancard" */).then(c => wrapFunctional(c.default || c))
export const ItemModal = () => import('../../components/item-modal.vue' /* webpackChunkName: "components/item-modal" */).then(c => wrapFunctional(c.default || c))
export const ItemNotesModal = () => import('../../components/item-notes-modal.vue' /* webpackChunkName: "components/item-notes-modal" */).then(c => wrapFunctional(c.default || c))
export const Items = () => import('../../components/items.vue' /* webpackChunkName: "components/items" */).then(c => wrapFunctional(c.default || c))
export const Keyboard = () => import('../../components/keyboard.vue' /* webpackChunkName: "components/keyboard" */).then(c => wrapFunctional(c.default || c))
export const KotCancellationModal = () => import('../../components/kot-cancellation-modal.vue' /* webpackChunkName: "components/kot-cancellation-modal" */).then(c => wrapFunctional(c.default || c))
export const KotReceiptLabel = () => import('../../components/kot-receipt-label.vue' /* webpackChunkName: "components/kot-receipt-label" */).then(c => wrapFunctional(c.default || c))
export const KotReceipt = () => import('../../components/kot-receipt.vue' /* webpackChunkName: "components/kot-receipt" */).then(c => wrapFunctional(c.default || c))
export const LoadingModal = () => import('../../components/loading-modal.vue' /* webpackChunkName: "components/loading-modal" */).then(c => wrapFunctional(c.default || c))
export const Locale = () => import('../../components/locale.vue' /* webpackChunkName: "components/locale" */).then(c => wrapFunctional(c.default || c))
export const LocationSearchBox = () => import('../../components/location-search-box.vue' /* webpackChunkName: "components/location-search-box" */).then(c => wrapFunctional(c.default || c))
export const Login = () => import('../../components/login.vue' /* webpackChunkName: "components/login" */).then(c => wrapFunctional(c.default || c))
export const NumberPad = () => import('../../components/number-pad.vue' /* webpackChunkName: "components/number-pad" */).then(c => wrapFunctional(c.default || c))
export const OnlineOrders = () => import('../../components/online-orders.vue' /* webpackChunkName: "components/online-orders" */).then(c => wrapFunctional(c.default || c))
export const OnlinePlatform = () => import('../../components/online-platform.vue' /* webpackChunkName: "components/online-platform" */).then(c => wrapFunctional(c.default || c))
export const OrderInvoiceSa = () => import('../../components/order-invoice-sa.vue' /* webpackChunkName: "components/order-invoice-sa" */).then(c => wrapFunctional(c.default || c))
export const OrderInvoiceSimplified = () => import('../../components/order-invoice-simplified.vue' /* webpackChunkName: "components/order-invoice-simplified" */).then(c => wrapFunctional(c.default || c))
export const OrderInvoiceTemplate2 = () => import('../../components/order-invoice-template2.vue' /* webpackChunkName: "components/order-invoice-template2" */).then(c => wrapFunctional(c.default || c))
export const OrderInvoice = () => import('../../components/order-invoice.vue' /* webpackChunkName: "components/order-invoice" */).then(c => wrapFunctional(c.default || c))
export const OrderReceiptSa = () => import('../../components/order-receipt-sa.vue' /* webpackChunkName: "components/order-receipt-sa" */).then(c => wrapFunctional(c.default || c))
export const OrderReceipt = () => import('../../components/order-receipt.vue' /* webpackChunkName: "components/order-receipt" */).then(c => wrapFunctional(c.default || c))
export const OrdersModal = () => import('../../components/orders-modal.vue' /* webpackChunkName: "components/orders-modal" */).then(c => wrapFunctional(c.default || c))
export const OtpModal = () => import('../../components/otp-modal.vue' /* webpackChunkName: "components/otp-modal" */).then(c => wrapFunctional(c.default || c))
export const Passcode = () => import('../../components/passcode.vue' /* webpackChunkName: "components/passcode" */).then(c => wrapFunctional(c.default || c))
export const PayCreditModal = () => import('../../components/pay-credit-modal.vue' /* webpackChunkName: "components/pay-credit-modal" */).then(c => wrapFunctional(c.default || c))
export const PayCreditReceipt = () => import('../../components/pay-credit-receipt.vue' /* webpackChunkName: "components/pay-credit-receipt" */).then(c => wrapFunctional(c.default || c))
export const Pinelabs = () => import('../../components/pinelabs.vue' /* webpackChunkName: "components/pinelabs" */).then(c => wrapFunctional(c.default || c))
export const PriceCategoryList = () => import('../../components/price-category-list.vue' /* webpackChunkName: "components/price-category-list" */).then(c => wrapFunctional(c.default || c))
export const Printer = () => import('../../components/printer.vue' /* webpackChunkName: "components/printer" */).then(c => wrapFunctional(c.default || c))
export const ProgressModal = () => import('../../components/progress-modal.vue' /* webpackChunkName: "components/progress-modal" */).then(c => wrapFunctional(c.default || c))
export const QuantityModal = () => import('../../components/quantity-modal.vue' /* webpackChunkName: "components/quantity-modal" */).then(c => wrapFunctional(c.default || c))
export const ReceiptPreview = () => import('../../components/receipt-preview.vue' /* webpackChunkName: "components/receipt-preview" */).then(c => wrapFunctional(c.default || c))
export const RefundModal = () => import('../../components/refund-modal.vue' /* webpackChunkName: "components/refund-modal" */).then(c => wrapFunctional(c.default || c))
export const Reports = () => import('../../components/reports.vue' /* webpackChunkName: "components/reports" */).then(c => wrapFunctional(c.default || c))
export const Sales = () => import('../../components/sales.vue' /* webpackChunkName: "components/sales" */).then(c => wrapFunctional(c.default || c))
export const Scanner = () => import('../../components/scanner.vue' /* webpackChunkName: "components/scanner" */).then(c => wrapFunctional(c.default || c))
export const Sell = () => import('../../components/sell.vue' /* webpackChunkName: "components/sell" */).then(c => wrapFunctional(c.default || c))
export const Settings = () => import('../../components/settings.vue' /* webpackChunkName: "components/settings" */).then(c => wrapFunctional(c.default || c))
export const ShareReceiptModal = () => import('../../components/share-receipt-modal.vue' /* webpackChunkName: "components/share-receipt-modal" */).then(c => wrapFunctional(c.default || c))
export const ShiftReceipt = () => import('../../components/shift-receipt.vue' /* webpackChunkName: "components/shift-receipt" */).then(c => wrapFunctional(c.default || c))
export const Skyband = () => import('../../components/skyband.vue' /* webpackChunkName: "components/skyband" */).then(c => wrapFunctional(c.default || c))
export const SyncDetails = () => import('../../components/sync-details.vue' /* webpackChunkName: "components/sync-details" */).then(c => wrapFunctional(c.default || c))
export const TableList = () => import('../../components/table-list.vue' /* webpackChunkName: "components/table-list" */).then(c => wrapFunctional(c.default || c))
export const TablesModal = () => import('../../components/tables-modal.vue' /* webpackChunkName: "components/tables-modal" */).then(c => wrapFunctional(c.default || c))
export const Tables = () => import('../../components/tables.vue' /* webpackChunkName: "components/tables" */).then(c => wrapFunctional(c.default || c))
export const TaxList = () => import('../../components/tax-list.vue' /* webpackChunkName: "components/tax-list" */).then(c => wrapFunctional(c.default || c))
export const TaxModal = () => import('../../components/tax-modal.vue' /* webpackChunkName: "components/tax-modal" */).then(c => wrapFunctional(c.default || c))
export const Tidypay = () => import('../../components/tidypay.vue' /* webpackChunkName: "components/tidypay" */).then(c => wrapFunctional(c.default || c))
export const TipModal = () => import('../../components/tip-modal.vue' /* webpackChunkName: "components/tip-modal" */).then(c => wrapFunctional(c.default || c))
export const WeighingScale = () => import('../../components/weighing-scale.vue' /* webpackChunkName: "components/weighing-scale" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
