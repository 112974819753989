export default function ({ $axios, store }) {
	$axios.onRequest((config) => {
		if (config.headers.common) {
			config.headers.common['Accept-Language'] = store.state.locale
		}
	})

	$axios.onError((error) => {
		console.error(error)

		if (error.response && [401, 410].includes(error.response.status) &&
		!(error.config.url === '/api/pos/v2/login')) {
			store.dispatch('logout')
		}
	})
}
