import $moment from 'moment'

const getters = {
	appVersionNumber (state) {
		return parseInt(state.appVersion.replace(/\./g, ''))
	},
	employeePolicy (state) {
		let policy = {
			isAdmin: state.employee.roles[0] ? state.employee.roles[0].isAdmin : false
		}

		if (state.employee.roles[0] && state.employee.roles[0].policy &&
		state.employee.roles[0].policy.appPolicy) {
			policy = Object.assign({}, policy, state.employee.roles[0].policy.appPolicy)
		}

		return policy
	},
	validDiscounts (state) {
		return state.discounts.filter((discount) => {
			return (
				(!discount.valid_from && !discount.expires_on) ||
				$moment().isBetween(discount.valid_from, discount.expires_on)
			) && (
				!discount.custom_attributes.days ||
				discount.custom_attributes.days.includes($moment().format('dddd').toLowerCase())
			) && (
				(!discount.customers?.length && !discount.customer_groups?.length) ||
				(
					state.cart.customer &&
					(
						discount.customers?.findIndex((c) => {
							return c.customer_id === state.cart.customer?.customer_id
						}) > -1 ||
						discount.customer_groups?.findIndex((cg) => {
							return cg.id === state.cart.customer?.customer_group?.id
						}) > -1
					)
				)
			)
		})
	},
	orderDiscounts (state, getters) {
		return getters.validDiscounts.filter((discount) => {
			return discount.entity !== 'dynamic' && discount.apply_discount_sub_total && !discount.is_automatic &&
				discount.discount_items.length === 0
		})
	},
	dynamicDiscount (state, getters) {
		return getters.validDiscounts.filter((discount) => {
			return discount.entity === 'dynamic'
		})[0] || null
	},
	discounts: (state, getters) => (type) => {
		return state.merchant.subscription && state.merchant.subscription.products.advanced_discount
			? getters.validDiscounts.filter((discount) => {
				const discountType = type === 'item' ? 'discount_items' : 'discount_categories'

				return !discount.apply_discount_sub_total && discount[discountType] && discount[discountType].length > 0
			})
			: []
	},
	autoOrderDiscount: (state, getters) => (type, value) => {
		return state.merchant.subscription && state.merchant.subscription.products.advanced_discount
			? getters.validDiscounts.filter((discount) => {
				return discount.buy_condition_type === type && discount.buy_condition_value <= value &&
					discount.apply_discount_sub_total && discount.is_automatic
			}).sort((a, b) => b.buy_condition_value - a.buy_condition_value)[0]
			: null
	},
	cartCategories: () => (cartItems, excludedItems = []) => {
		return cartItems.reduce((cartCategories, i) => {
			const discountQty = i.item_discount.reduce((sum, item) => {
				sum += (item?.quantity || 0)

				return sum
			}, 0)

			if (!excludedItems?.includes(i.id)) {
				if (cartCategories[i.category_id]) {
					cartCategories[i.category_id].quantity += i.quantity - discountQty
				} else {
					cartCategories[i.category_id] = {
						quantity: i.quantity - discountQty
					}
				}
			}

			return cartCategories
		}, {})
	},
	isTestingDevice: (state) => {
		return state.device.name === state.merchant.mCode
	},
	isGiftCard: (state) => {
		const findGiftCardItem = state.cart.items.find((item) => {
			return item?.custom_attributes?.gift_card || item.discounts?.find(d => d.entity === 'gift_card')
		})

		return !!findGiftCardItem
	}
}

export default getters
