export default () => {
    if (process.server) {
        return;
    }

    const detect = () => 'Buffer' in window;

    if (detect()) {
        return;
    }

    return new Promise((resolve, reject) => {
        require.ensure([
            "buffer"
        ], function (require) {
            const polyfill = require("buffer");

            const install = (buffer) => { window.Buffer = buffer.Buffer };

            install(polyfill);

            resolve();
        });
    });
}