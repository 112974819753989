import { setInteractionMode, extend, configure } from 'vee-validate'
import libphonenumber from 'google-libphonenumber'
import {
	// eslint-disable-next-line
	alpha_num, alpha_spaces, alpha_dash, email, is, length, max, max_value, min, min_value, numeric, required, integer, mimes
} from 'vee-validate/dist/rules'

export default ({ app }) => {
	configure({
		defaultMessage: (field, values) => {
			return app.i18n.t(`validation.${values._rule_}`, values)
		}
	})

	setInteractionMode('eager')

	extend('integer', integer)
	extend('alpha_num', alpha_num)
	extend('alpha_spaces', alpha_spaces)
	extend('alpha_dash', alpha_dash)
	extend('email', email)
	extend('is', is)
	extend('length', length)
	extend('max', max)
	extend('max_value', max_value)
	extend('min', min)
	extend('min_value', min_value)
	extend('numeric', numeric)
	extend('required', required)
	extend('mimes', mimes)
	extend('equals', {
		validate: (value, { other }) => value === other,
		params: [{ name: 'other', isTarget: true }]
	})
	extend('decimal', {
		validate: (value, { count }) => {
			value = value.toString()

			if (value.includes('.')) {
				return value.split('.')[1].length <= count
			}

			return true
		},
		params: ['count']
	})
	extend('mobile', {
		validate: (value, { code }) => {
			if (value.length < 4) {
				return false
			} else {
				try {
					const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()

					return phoneUtil.isValidNumberForRegion(phoneUtil.parseAndKeepRawInput('' + phoneUtil.getCountryCodeForRegion(code) + value, code), code)
				} catch (err) {
					return false
				}
			}
		},
		params: ['code']
	})
	extend('uniqueTaxType', {
		validate: value => value.map(t => t.inclusion_type).every((t, i, arr) => t === arr[0])
	})
	extend('alphaNumSpace', {
		// eslint-disable-next-line
		validate: value => !/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(value)
	})
	extend('greaterThanZero', {
		validate: value => value > 0
	})
	extend('dateGreaterThan', {
		validate: (value, { date }) => {
			if (!(date instanceof Date)) {
				date = new Date()
			}

			return (value.startDate || value) > date
		},
		params: ['date']
	})
}
