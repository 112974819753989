import Vue from 'vue'
import sync from '~/assets/js/sync'
import utils from '~/assets/js/utils'
import events from '~/assets/js/events'

export default ({ app }) => {
	// to access inside store
	app.$swal = Vue.$swal
	app.getDataURL = utils.getDataURL
	app.getUniqueId = utils.getUniqueId
	app.evenly = utils.evenly
	app.getUpdatedAtDateTime = utils.getUpdatedAtDateTime
	app.applyMultiPricing = utils.applyMultiPricing
	app.validateConditions = utils.validateConditions
	app.convertCase = utils.convertCase
	app.filterItem = utils.filterItem
	app.syncAll = sync(app).syncAll
	app.sleep = utils.sleep
	app.objToJson = utils.objToJson

	Vue.mixin({
		methods: {
			...sync(app),
			...utils,
			...events
		}
	})
}
