import timemachine from 'timemachine'

timemachine.reset()

if (Intl.DateTimeFormat().resolvedOptions().timeZone === 'Asia/Amman') {
	const date = new Date()

	date.setHours(date.getHours() + 1)
	timemachine.config({
		dateString: date.toString(),
		tick: true
	})
}
